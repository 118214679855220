<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6">
                <select class='form-control' v-model="state.optionFields.shift.val">
                    <option v-for="[index, shift] in cache.shiftCache.SHIFTS" v-bind:key="index" :value="index">{{ shift }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import shiftCache from '@/cache/shift.cache.js';
    export default {
        name: "Options",
        data() {
            return {
                state: store.state,
                cache: {
                    shiftCache
                },
            }
        }
    }
</script>

<style scoped>

</style>